import React from 'react';

const Footer = () => {
    return (
        <div>
            <div className="footer style-1">
                <div className="copyright">
                    <p>
                        Copyright © Designed &amp; Developed by{" "}
                        <a>
                            Coin Verse
                        </a>{" "}
                        <span className="current-year">2024</span>
                    </p>
                </div>
            </div>


        </div>
    );
}

export default Footer;


